import React from 'react'
import { appGreyColor, appPrimaryColor } from 'theme/colors'

const PharmacyLogo = ({ active = false }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8332 6.41667C13.5625 6.41667 14.262 6.7064 14.7777 7.22212C15.2934 7.73785 15.5832 8.43732 15.5832 9.16667V18.3333C15.5832 18.5764 15.4866 18.8096 15.3147 18.9815C15.1428 19.1534 14.9096 19.25 14.6665 19.25H7.33317C7.09006 19.25 6.8569 19.1534 6.68499 18.9815C6.51308 18.8096 6.4165 18.5764 6.4165 18.3333V9.16667C6.4165 8.43732 6.70624 7.73785 7.22196 7.22212C7.73769 6.7064 8.43716 6.41667 9.1665 6.41667M12.8332 6.41667H9.1665M12.8332 6.41667V3.66667C12.8332 3.42355 12.7366 3.19039 12.5647 3.01849C12.3928 2.84658 12.1596 2.75 11.9165 2.75H10.0832C9.84006 2.75 9.6069 2.84658 9.43499 3.01849C9.26308 3.19039 9.1665 3.42355 9.1665 3.66667V6.41667M9.1665 12.8333H12.8332M10.9998 11V14.6667" stroke={active ? appPrimaryColor : appGreyColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default PharmacyLogo