import React from 'react'
import { appGreyColor, appPrimaryColor } from 'theme/colors'

const ChatLogo = ({ active = false }) => {
  return (
    <svg width="21" height="21" viewBox="-2 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.33333 6.2513H12.6667M5.33333 9.91797H8.54167M7.60667 15.8873L5.33333 17.2513V14.5013H3.5C2.77065 14.5013 2.07118 14.2116 1.55546 13.6958C1.03973 13.1801 0.75 12.4806 0.75 11.7513V4.41797C0.75 3.68862 1.03973 2.98915 1.55546 2.47343C2.07118 1.9577 2.77065 1.66797 3.5 1.66797H14.5C15.2293 1.66797 15.9288 1.9577 16.4445 2.47343C16.9603 2.98915 17.25 3.68862 17.25 4.41797V8.08464M14.5 18.168L17.5708 15.1576C17.7591 14.9749 17.9089 14.7563 18.0114 14.5147C18.1138 14.2731 18.1668 14.0135 18.1672 13.7511C18.1677 13.4886 18.1155 13.2288 18.0139 12.9869C17.9122 12.745 17.7631 12.5259 17.5754 12.3425C17.1924 11.9679 16.6782 11.7577 16.1424 11.7567C15.6066 11.7557 15.0916 11.9639 14.7072 12.337L14.5018 12.5387L14.2974 12.337C13.9144 11.9627 13.4004 11.7527 12.8649 11.7516C12.3293 11.7506 11.8145 11.9587 11.4301 12.3315C11.2417 12.5142 11.0918 12.7328 10.9893 12.9743C10.8868 13.2159 10.8337 13.4755 10.8332 13.7379C10.8327 14.0003 10.8848 14.2602 10.9863 14.5021C11.0879 14.7441 11.2369 14.9632 11.4246 15.1466L14.5 18.168Z" stroke={active ? appPrimaryColor : appGreyColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ChatLogo