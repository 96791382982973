import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "store/baseQuery";

export const pharmacyApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "pharmacyApi",
  endpoints: (build) => ({
    addPharmacyLisence: build.mutation({
      query(body) {
        return {
          url: `/api/personas/pharmacy/licenses/`,
          method: "POST",
          body
        };
      },
    }),
    deletePharmacyLisence: build.mutation({
      query(id) {
        return {
          url: `/api/personas/pharmacy/licenses/${id}/`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useAddPharmacyLisenceMutation,
  useDeletePharmacyLisenceMutation,
} = pharmacyApi;
