import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = initializeApp({
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
  measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID
});

export const messaging = getMessaging(firebaseConfig);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FCM_VAPID_KEY, // Firebase VAPID key
    });

    if (currentToken) {
      return currentToken;  // Return the token
    } else {
      console.log("No registration token available. Request permission to generate one.");
      return null;  // Return null if no token is available
    }
  } catch (err) {
    console.error("An error occurred while retrieving token: ", err);
    return null;  // Return null in case of an error
  }
};


export const onMessageListener = (callback) => {
  const unsubscribe = onMessage(messaging, (payload) => {
    callback(payload);  // Use a callback to handle each new message
  });
  return unsubscribe;
};


export const getNotificationPermission = async () => {
  // Notification.requestPermission().then((permission) => {
  //   debugger;
  //   if (permission === 'granted') {
  //     return true
  //   } else {
  //     console.log('Notifications are blocked. Please enable them in your browser settings.');
  //     alert('Please enable notifications in your browser settings.');
  //     return false;
  //   }
  // }).catch((e) => {
  //   console.log('Error in getting notification permission:', e);
  // })
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      return true
    } else {
      console.log('Notifications are blocked. Please enable them in your browser settings.');
      alert('Please enable notifications in your browser settings.');
      return false;
    }
  } catch (e) {
    console.log('Error in getting notification permission:', e);
  }
}


