import { Drawer, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import SidebarComponent from './SidebarComponent'
import { useDispatch, useSelector } from 'react-redux'
import { setDrawer } from 'slices/user.slice'
import { useTheme } from '@emotion/react'
import { useLocation } from 'react-router-dom'

const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const openDrawer = useSelector((state) => state.user.openDrawer);

  const handleClose = () => {
    dispatch(setDrawer(false));
  }

  useEffect(() => {
    handleClose();
  }, [location])
  return (
    <>
      {isSmallScreen ?
        <Drawer
          anchor="left"
          open={openDrawer}
          onClose={handleClose}
        // sx={{ display: { xs: 'block', sm: 'none' } }}
        >
          <SidebarComponent />
        </Drawer>
        : <SidebarComponent />
      }
    </>
  )
}

export default Sidebar