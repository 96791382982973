import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { appBlackcolor, appGreyColor, appPrimaryColor, appWhite, labelColor } from "./colors";
// import { BorderTop } from "@mui/icons-material";
// import { red } from "@mui/material/colors";

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1280,
      xxl: 1920,
    },
  },
  palette: {
    // subheading: {
    //   primary: appBlackLight
    // },
    background: {
      default: "#ffffff",
    },
    text: {
      primary: appBlackcolor,
      secondary: appWhite,
      appGrey: appGreyColor,
      labelColor: labelColor,
    },
    primary: {
      main: appPrimaryColor,
    },
    info: {
      main: appPrimaryColor,
      light: appBlackcolor
    },
    // secondary: {
    //   main: appSecondaryColor,
    //   light: appGreyColor
    // },
    // error: {
    //   main: red.A400,
    // },
  },
  typography: {
    fontFamily: ["Manrope", "sans-serif"].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: "none",
          borderRadius: '8px',
          maxHeight: '44px',
          padding: '12px 16px',
          [theme.breakpoints.down('sm')]: {
            padding: "8px 12px",
            fontSize: "0.7rem",
          }
        }),
      },
      variants: [
        // {
        //   props: { color: "secondary" },
        //   style: {
        //     background: `${appSecondaryColor}!important`,
        //     color: `${appBlackcolor}!important`,
        //   },
        // },
        {
          props: { variant: "contained", type: "submit" },
          style: {
            // borderRadius: '8px',
            // background: `inherit!important`,
            // color: `${appBlackcolor}!important`,
          },
        },
        {
          props: { variant: "export" },
          style: {
            // background: `${FooterLinkPrimary}!important`,
            color: appBlackcolor,
            border: `1px solid #B4B9C3`,
            fontWeight: 600,
            // borderRadius: "8px"
          },
        },
        {
          props: { className: 'shopNow' },
          style: {
            backgroundColor: appWhite,
            color: appBlackcolor,
            border: '1px solid #B4B9C3',
            ':hover': {
              backgroundColor: appWhite
            }
          }
        },
        {
          props: { className: 'cancel-appointment' },
          style: {
            height: '36px',
            backgroundColor: appWhite,
            fontWeight: 600,
            color: "#D92D20",
            border: `1px solid #B4B9C3`,
            ':hover': {
              backgroundColor: appWhite
            }
          }
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#A0A9BB',
          borderRadius: '4px',
          border: '1px',
          padding: '4px 8px',
        },
      },
      variants: [
        {
          props: { className: 'price-card' },
          style: {
            // borderRadius: '50%!important',
          }
        }
      ]
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // fontSize: "0.875rem!important",
          borderRadius: "8px!important",
          height: '2.875rem',
          backgroundColor: appWhite,
          // '& input::placeholder': {
          //   // fontWeight: 300,
          //   // color: 'red', // Set placeholder text color
          //   // fontStyle: 'italic', // Set placeholder text style
          // },
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { className: 'textArea' },
          style: {
            '& .MuiInputBase-root': {
              height: 'auto',
            }
          }
        }
      ]
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '4px',
          }
        },
      },
    },
    // MuiChip: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: appGreyColor,
    //       fontSize: '0.75rem',
    //       borderRadius: '27px'
    //     }
    //   },
    // },
    // MuiSvgIcon: {
    //   styleOverrides: {
    //     color: `${appBlackcolor}!imporant`
    //   }
    // },
    // MuiDivider: {
    //   styleOverrides: {
    //     height: 'auto!important',
    //     borderWidth: '1px !important',
    //     borderColor: '#E6F0FF !important'
    //   }
    // },
    MuiAlert: {
      styleOverrides: {
        root: {
          '.MuiAlert-message::first-letter': {
            textTransform: 'capitalize',
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: '20px',
          borderRadius: '14px!important',
          boxShadow: 'none',
          '::before': {
            display: 'none',
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0px',
          fontWeight: 700,
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px',
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 12,
          paddding: '2px 8px',
          fontWeight: 500,
          textTransform: 'capitalize',
        }
      },
      variants: [
        {
          props: { variant: 'Approved' },
          style: { backgroundColor: '#EEF8EE', color: '#00914B' }
        },
        {
          props: { variant: 'Pending' },
          style: { backgroundColor: '#FFF3D3', color: '#B45309' }
        },
        {
          props: { variant: 'PARTIALLY_FULFILLED' },
          style: { backgroundColor: '#FFF3D3', color: '#B45309' }
        },
        {
          props: { variant: 'Rejected' },
          style: { backgroundColor: '#FFF3D3', color: '#B45309' }
        },
        {
          props: { className: 'Completed' },
          style: { backgroundColor: '#F4EDFD', color: '#8C34FF' }
        },
        {
          props: { className: 'Scheduled' },
          style: { backgroundColor: '#E5F6FF', color: '#187BEF' }
        },
        {
          props: { className: 'In Progress' },
          style: { backgroundColor: '#FBF4F8', color: '#B53673' }
        },
        {
          props: { className: 'Cancelled' },
          style: { backgroundColor: '#FBEEED', color: '#E53939' }
        },
        {
          props: { className: 'UNFULFILLED' },
          style: { backgroundColor: '#FBEEED', color: '#E53939' }
        },
        {
          props: { className: 'paid' },
          style: { backgroundColor: '#EEF8EE', color: '#00914B' }
        },
        {
          props: { className: 'FULFILLED' },
          style: { backgroundColor: '#EEF8EE', color: '#00914B' }
        },
        {
          props: { className: 'primary' },
          style: {
            backgroundColor: appPrimaryColor,
            color: appWhite,
            ':hover': {
              backgroundColor: appPrimaryColor
            }
          }
        },
        {
          props: { className: 'repeated' },
          style: {
            backgroundColor: appWhite,
            color: appPrimaryColor,
            border: `1px solid ${appPrimaryColor}`,
            height: '22px',
          }
        },
        {
          props: { className: 'Refund' },
          style: { backgroundColor: '#E5F6FF', color: '#187BEF' }
        },
        {
          props: { className: 'variant-size' },
          style: {
            fontSize: 10,
            paddding: '0px',
            fontWeight: 600,
            height: '24px',
            backgroundColor: appWhite,
            color: "#49443e",
            border: `1px solid #49443e`,
            ':hover': {
              color: appWhite,
              backgroundColor: "#49443e",
            }
          }
        },
      ]
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#757575',
          padding: '8px 4px'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          gap: '8px',
          margin: 0
        }
      }
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          gap: '16px'
        }
      }
    },
    // MuiToggleButtonGroup: {
    //   variants: [
    //     {
    //       props: { className: 'categoryButtons' },
    //       style: {
    //         // borderRadius: '8px',
    //         // padding: '4px',
    //         // backgroundColor: "#F5F5F5",
    //       }
    //     }
    //   ]
    // },
    MuiToggleButton: {
      variants: [
        {
          props: { className: 'categoryButton' },
          style: {
            fontSize: '14px',
            fontWeight: 400,
            color: '#212121',
            textTransform: 'none',
            border: 'none',
            padding: '8px 16px',
            '&.Mui-selected': {
              boxShadow: '0px 1px 3px 0px #1018280A',
              backgroundColor: '#FFFFFF',
              fontWeight: 600,
              borderRadius: '6px!important',
            }
          }
        },
        {
          props: { className: 'calendar-toolbar' },
          style: {
            fontSize: '14px',
            color: '#212121',
            textTransform: 'none',
            padding: '8px 16px',
            fontWeight: 600,
            borderRadius: '6px',
          }
        },
        {
          props: { className: 'time-slot' },
          style: {
            fontSize: '16px',
            fontWeight: 400,
            color: appPrimaryColor,
            textTransform: 'none',
            border: `1px solid ${appPrimaryColor}`,
            padding: '8px 16px',
            borderRadius: '6px!important',
            minWidth: '180px',
            '&:hover': {
              backgroundColor: '#F9EFF4',
            },
            '&.Mui-selected': {
              backgroundColor: appPrimaryColor,
              fontWeight: 600,
              color: appWhite,
              '&:hover': {
                backgroundColor: appPrimaryColor,
              }
            },
            '&.time-slot': {
              '&.MuiToggleButtonGroup-middleButton': {
                borderTop: `1px solid ${appPrimaryColor}`
              },
              '&.MuiToggleButtonGroup-lastButton': {
                borderTop: `1px solid ${appPrimaryColor}`
              }
            }
          }
        },
      ]
    },
    MuiRating: {
      styleOverrides: {
        root: {
          fontSize: '2rem',
          gap: '16px',
          margin: '0px auto',
          padding: '8px'
        }
      }
    },

    // MuiSlider: {
    //   styleOverrides: {
    //     color: `${appWhite}!important`
    //   }
    // }
  },
});
theme = responsiveFontSizes(theme);
export default theme;
