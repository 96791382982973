import { Outlet, Navigate } from "react-router-dom";
import Layout from "components/Layout";
import useAuth from "shared/hooks/useAuth";
import Loader from "components/Loader";
import { ApolloProvider } from "@apollo/client";
import useApolloClient from "graphql/useApolloClient";

const ProtectedRoute = () => {
  const { isLoading, authenticated, healthieToken = '', userId } = useAuth();
  const { client } = useApolloClient({ healthieToken, userId });
  if (isLoading) {
    return <Loader pageLoader />
  }

  if (!client && authenticated) {
    return <Loader pageLoader />
  }

  if (
    authenticated
  ) {
    return (
      <ApolloProvider client={client}>
        <Layout>
          <Outlet />
        </Layout>
      </ApolloProvider>
    );
  }

  return <Navigate to="/login" />;
};

export default ProtectedRoute;
