import { Stack, Divider, IconButton } from "@mui/material"
// import ZyvlyLogo from "assets/logos/ZyvlyLogo"
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { appBorderColor } from "theme/colors";
import ProfileDropdown from "./ProfileDropdown";
import NotificationsDropdown from "./NotificationsDropdown";
import SupplementCart from "./SupplemendCart";
import Link from "components/Link";
import { useDispatch } from "react-redux";
import { setDrawer } from "slices/user.slice";
import headerLogo from 'assets/logos/header-logo.svg'

// const iconButtonStyle = {
//   border: `1px solid #DDDFE4`,
//   color: '#9E9E9E',
//   borderRadius: '50%',
// }

const Header = ({ noMenu = false, isCheckout = false }) => {
  const dispatch = useDispatch();
  const openDrawer = () => {
    dispatch(setDrawer(true));
  }

  return (
    <Stack
      height="60px"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      // px={3}
      borderBottom={`1px solid ${appBorderColor}`}
      sx={{
        px: {
          xs: 2,
          sm: 3
        }
      }}
    >
      {/* <Stack direction="row" alignItems="center"> */}
      <Stack
        sx={{
          display: {
            xs: isCheckout ? 'block' : 'none',
            sm: 'block'
          }
        }}
      >
        <Link
          // linHeight={0}
          to={"/appointments"}
          className="logo"
        >
          <img
            src={headerLogo}
            loading="lazy"
            alt='logo'
          />
          {/* {headerLogo} */}
          {/* <ZyvlyLogo height="26" width="81" /> */}
          {/* add breadcrumb here  */}
        </Link>
      </Stack>
      <IconButton
        onClick={openDrawer}
        sx={{
          display: {
            xs: isCheckout ? 'none' : 'block',
            sm: 'none'
          }
        }}>
        <MenuIcon />
      </IconButton>
      {/* </Stack> */}
      {!noMenu ? <Stack direction="row" alignItems="center" sx={{ gap: { xs: 1, sm: 2 } }}>
        <NotificationsDropdown />
        {/* <IconButton sx={iconButtonStyle}>
          <NotificationsOutlinedIcon />
        </IconButton> */}
        {/* <IconButton sx={iconButtonStyle}>
          <ShoppingCartOutlinedIcon />
        </IconButton> */}
        <SupplementCart />
        <Divider orientation="vertical" sx={{ borderBottomWidth: '40px', bordercolor: '#c4c4c4', borderRightWidth: '2px' }} />
        <ProfileDropdown />
      </Stack> : null}
    </Stack>
  )
}

export default Header