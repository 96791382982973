import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "store/baseQuery";

export const providerApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "providerApi",
  // tagTypes: ['USER'],
  endpoints: (build) => ({
    addQualification: build.mutation({
      query(body) {
        return {
          url: `/api/personas/provider/qualifications/`,
          method: "POST",
          body
        };
      },
    }),
    deleteQualification: build.mutation({
      query(id) {
        return {
          url: `/api/personas/provider/qualifications/${id}/`,
          method: "DELETE",
        };
      },
    }),
    addExperience: build.mutation({
      query(body) {
        return {
          url: `/api/personas/provider/experiences/`,
          method: "POST",
          body
        };
      },
    }),
    deleteExperience: build.mutation({
      query(id) {
        return {
          url: `/api/personas/provider/experiences/${id}/`,
          method: "DELETE",
        };
      },
    }),
    addSpecialization: build.mutation({
      query(body) {
        return {
          url: `api/personas/provider/specializations/`,
          method: "POST",
          body
        };
      },
    }),
    deleteSpecialization: build.mutation({
      query(id) {
        return {
          url: `api/personas/provider/specializations/${id}/`,
          method: "DELETE",
        };
      },
    }),
    addProviderLisence: build.mutation({
      query(body) {
        return {
          url: `api/personas/provider/licenses/`,
          method: "POST",
          body
        };
      },
    }),
    deleteProviderLisence: build.mutation({
      query(id) {
        return {
          url: `/api/personas/provider/licenses/${id}/`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useAddQualificationMutation,
  useDeleteQualificationMutation,
  useAddExperienceMutation,
  useDeleteExperienceMutation,
  useAddProviderLisenceMutation,
  useDeleteProviderLisenceMutation,
  useAddSpecializationMutation,
  useDeleteSpecializationMutation
} = providerApi;
