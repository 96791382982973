import { Stack, ListItem, List, ListItemButton, ListItemIcon, ListItemText, IconButton, useMediaQuery } from '@mui/material';
import { appBlackcolor, appBorderColor, appGreyColor } from 'theme/colors';
// import DashboardLogo from 'assets/logos/DashboardLogo';
import Link from 'components/Link';
import { useLocation } from 'react-router-dom';
import AppointmentLogo from 'assets/logos/AppointmentLogo';
import HealthRecordLogo from 'assets/logos/HealthRecordLogo';
import PharmacyLogo from 'assets/logos/PharmacyLogo';
import SettingsLogo from 'assets/logos/SettingsLogo';
import HealthArticlesLogo from 'assets/logos/HealthArticlesLogo';
// import { routes } from 'shared/routes';
// import useGetCurrentUser from 'shared/hooks/useGetCurrentUser';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebar } from 'slices/user.slice';
// import { roles } from 'utils/constants';
import OrderTrackingLogo from 'assets/logos/OrderTrackingLogo';
import { useTheme } from '@emotion/react';
import ChatLogo from 'assets/logos/ChatLogo';

const sidebarRoutes = [
  // { id: 1, label: 'My Health', value: 'dashboard', icon: (props) => <DashboardLogo {...props} />, path: "/dashboard" },
  { id: 1, label: 'Appointments', value: 'appointments', icon: (props) => <AppointmentLogo {...props} />, path: "/appointments" },
  // { id: 3, label: 'Medicines', value: 'medicines', icon: (props) => <AppointmentLogo {...props} />, path: "/medicines" },
  { id: 2, label: 'My Health', value: 'healthRecords', icon: (props) => <HealthRecordLogo {...props} />, path: "/health-records" },
  { id: 3, label: 'Chat', value: 'chat', icon: (props) => <ChatLogo {...props} />, path: "/chat" },
  // { id: 5, label: 'Patients', value: 'patients', icon: (props) => <HealthRecordLogo {...props} />, path: "/patient-management" },
  // { id: 6, label: 'Order Management', value: 'order-management', icon: (props) => <AppointmentLogo {...props} />, path: "/order-management" },
  { id: 4, label: 'Supplement Store', value: 'pharmacy', icon: (props) => <PharmacyLogo {...props} />, path: "/supplement-store" },
  { id: 5, label: 'Order Tracking', value: 'order-tracking', icon: (props) => <OrderTrackingLogo {...props} />, path: "/order-tracking" },
  { id: 6, label: 'Settings', value: 'settings', icon: (props) => <SettingsLogo {...props} />, path: "/settings" },
]


const activeTab = {
  backgroundColor: "#F9EFF4",
  color: appBlackcolor,
}

const SidebarComponent = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // const { data } = useGetCurrentUser();
  // const { role } = data || {};
  // const userRoutes = sidebarRoutesComponent.filter(({ path }) => routes[role].includes(path));
  const sidebar = useSelector((state) => state.user.sidebar);


  const tabRootStyle = {
    borderRadius: '8px',
    minWidth: (sidebar || isSmallScreen) ? '220px' : '48px',
    color: appGreyColor,
  }

  const tabStyle = {
    padding: '10px 12px',
    '& .MuiListItemIcon-root ': { minWidth: (sidebar || isSmallScreen) ? '32px' : '24px' },
    ":hover": { backgroundColor: "inherit" }
  };

  const handleArrowButton = () => {
    dispatch(setSidebar(!sidebar));
  }

  return (
    <Stack
      height="calc(100vh - 105px)"
      width={(sidebar || isSmallScreen) ? "238px" : '76px'}
      borderRight={`1px solid ${appBorderColor}`}
      p="22px 14px"
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      position="sticky"
      left={0}
      bottom={0}
      top={0}
      sx={{
        height: {
          xs: '100vh',
          sm: "calc(100vh - 105px)"
        }
      }}
    // overflow='hidden'
    >
      <List>
        {sidebarRoutes.map((item) => (
          <Link id={item.id} to={item.path} key={item.id} component='span'>
            <ListItem disablePadding sx={pathname === item.path ? { ...tabRootStyle, ...activeTab } : tabRootStyle}
            >
              <ListItemButton disableRipple sx={tabStyle}>
                <ListItemIcon>
                  {item.icon({ active: pathname === item.path ? true : false })}
                </ListItemIcon>
                {(sidebar || isSmallScreen) ? <ListItemText primaryTypographyProps={{
                  variant: 'body2',
                  fontWeight: 500
                }} primary={item.label} /> : null}
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      {/* {roles.PATIENT === role ? */}
      <Link to="/health-articles" component='span'>
        <ListItem sx={pathname === "/health-articles" ? { ...tabRootStyle, ...activeTab, padding: "0px" } : { ...tabRootStyle, padding: "0px" }}>
          <ListItemButton disableRipple sx={tabStyle}>
            <ListItemIcon>
              <HealthArticlesLogo
                active={pathname === '/health-articles'}
              />
            </ListItemIcon>
            {(sidebar || isSmallScreen) ? <ListItemText primaryTypographyProps={{
              variant: 'body2',
              fontWeight: 500
            }} primary="Health Articles" /> : null}
          </ListItemButton>
        </ListItem>
      </Link>
      {/* : null} */}
      <IconButton sx={{
        display: {
          xs: 'none',
          sm: 'flex'
        },
        position: 'absolute',
        top: '40px',
        right: '-12px',
        border: '1px solid #DDDFE4',
        borderRadius: '50%',
        padding: '4px',
        '&:hover': {
          backgroundColor: 'inherit',
        },
        zIndex: 1300
      }}
        onClick={handleArrowButton}
      >
        <ArrowBackIosNewOutlinedIcon sx={{ color: '#49546B', fontSize: '1rem', rotate: sidebar ? '0deg' : '180deg' }} />
      </IconButton>
    </Stack>
  )
}

export default SidebarComponent