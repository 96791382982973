import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

const Loader = ({ pageLoader = false }) => {
  const sidebar = useSelector((state) => state.user.sidebar);
  return (
    <Box
      width={pageLoader ? '100%' : sidebar ? "calc(100vw - 267px)" : "calc(100vw - 105px)"}
      height={pageLoader ? '100vh' : "90vh"}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress
        variant="indeterminate"
        color='primary'
        size={40}
        thickness={4}
        value={100}
      />
    </Box>
  )
}


export const QueryLoader = ({ sx, size = 30, thickness = 3 }) => (
  <Box
    // width={pageLoader ? '100%' : sidebar ? "calc(100vw - 267px)" : "calc(100vw - 105px)"}
    // height={pageLoader ? '100vh' : "90vh"}
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={sx}
  >
    <CircularProgress
      variant="indeterminate"
      color='primary'
      size={size}
      thickness={thickness}
    // value={100}
    />
  </Box>
)

export default Loader