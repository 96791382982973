import { createSlice } from "@reduxjs/toolkit";
// import { localStorageService } from "shared/services/localStorageService";

const initialState = {
  userInfo: {},
  sidebar: true,
  // isFirstLogin: false,
  unreadNotifications: [],
  openDrawer: false,
  count: true,
  // firstTimeLogin: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { healthie_api_token, ...rest } = action.payload || {};
      // if (!localStorageService.getHealthieToken()) {
      //   localStorageService.setHealthieToken(healthie_api_token);
      // }
      state.userInfo = { ...state?.userInfo, ...rest };
    },
    resetUser: (state) => {
      state.userInfo = {};
    },
    setSidebar: (state, action) => {
      state.sidebar = action.payload;
    },
    // setFirstLogin: (state, action) => {
    //   state.isFirstLogin = action.payload;
    // },
    setUnreadNotifications: (state, action) => {
      state.unreadNotifications = [...state.unreadNotifications, action?.payload];
    },
    resetUnreadNotifications: (state) => {
      state.unreadNotifications = [];
    },
    setDrawer: (state, action) => {
      state.openDrawer = action.payload;
    },
    setcount: (state, action) => {
      state.count = action.payload;
    },
    // setFirstTimeLogin: (state, action) => {
    //   state.firstTimeLogin = action.payload;
    // },
  },
});

export const {
  setUser,
  resetUser,
  setSidebar,
  // setFirstLogin,
  setUnreadNotifications,
  resetUnreadNotifications,
  setDrawer,
  setcount,
  // setFirstTimeLogin,
} = userSlice.actions;

export default userSlice.reducer;
