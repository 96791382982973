import React from 'react'
import { appGreyColor, appPrimaryColor } from 'theme/colors'

const OrderTrackingLogo = ({ active = false }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9998 2.75L11.3675 2.09632C11.1392 1.96789 10.8605 1.96789 10.6321 2.09632L10.9998 2.75ZM18.3332 6.875H19.0832C19.0832 6.60405 18.937 6.35416 18.7009 6.22132L18.3332 6.875ZM18.3332 15.125L18.7009 15.7787C18.937 15.6458 19.0832 15.396 19.0832 15.125H18.3332ZM10.9998 19.25L10.6321 19.9037C10.8605 20.0321 11.1392 20.0321 11.3675 19.9037L10.9998 19.25ZM3.6665 15.125H2.9165C2.9165 15.396 3.06265 15.6458 3.29881 15.7787L3.6665 15.125ZM3.6665 6.875L3.29881 6.22132C3.06265 6.35416 2.9165 6.60405 2.9165 6.875H3.6665ZM10.6321 3.40368L17.9655 7.52868L18.7009 6.22132L11.3675 2.09632L10.6321 3.40368ZM17.5832 6.875V15.125H19.0832V6.875H17.5832ZM17.9655 14.4713L10.6321 18.5963L11.3675 19.9037L18.7009 15.7787L17.9655 14.4713ZM11.3675 18.5963L4.0342 14.4713L3.29881 15.7787L10.6321 19.9037L11.3675 18.5963ZM4.4165 15.125V6.875H2.9165V15.125H4.4165ZM4.0342 7.52868L11.3675 3.40368L10.6321 2.09632L3.29881 6.22132L4.0342 7.52868ZM11.3675 11.6537L18.7009 7.52868L17.9655 6.22132L10.6321 10.3463L11.3675 11.6537ZM10.2498 11V19.25H11.7498V11H10.2498ZM11.3675 10.3463L4.0342 6.22132L3.29881 7.52868L10.6321 11.6537L11.3675 10.3463Z" fill={active ? appPrimaryColor : appGreyColor} />
    </svg>
  )
}

export default OrderTrackingLogo