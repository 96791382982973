export const appPrimaryColor = "#B53673";
export const appWhite = "#ffffff";
// export const appSecondaryColor = "#F5F9FF";
export const appBlackcolor = "#000000";
export const appGreyColor = "#616161";
export const labelColor = "#3B3B3B";
export const characterValidatorChecked = "#5CB85C";
export const appBorderColor = '#E4E7EC';
// export const appBlackLight = '#475467';
// export const appGreyColor = "#EAEFF9";
// export const appToggleButtonBorder = '#E1E6EF';
// export const appTabColor = '#344054';
// export const appMediaTabColor = '#444A5A';
// export const FooterLinkPrimary = "#1053D4";
// export const FooterLinkSecondary = "#1E2833";
// export const ActiveBlockColor = "#11A0F9";
// export const LibraryBorderColor = "#DEE4EE";
// export const VideoPlayerGrayColor = "#9DA3AE";