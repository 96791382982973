import Loader from "components/Loader";
import { Outlet, Navigate } from "react-router-dom";
import useAuth from "shared/hooks/useAuth";

const UnProtectedRoutes = () => {
  const { isLoading, authenticated } = useAuth();

  return isLoading ? (
    <Loader pageLoader />
  ) : authenticated ? (
    <Navigate to="/appointments" />
  ) : (
    <Outlet />
  );
};

export default UnProtectedRoutes;
