import React from 'react'
import { appGreyColor, appPrimaryColor } from 'theme/colors'

const HealthRecordLogo = ({ active = false }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.25016 4.58333H6.41683C5.9306 4.58333 5.46428 4.77649 5.12047 5.1203C4.77665 5.46412 4.5835 5.93044 4.5835 6.41667V17.4167C4.5835 17.9029 4.77665 18.3692 5.12047 18.713C5.46428 19.0568 5.9306 19.25 6.41683 19.25H15.5835C16.0697 19.25 16.536 19.0568 16.8799 18.713C17.2237 18.3692 17.4168 17.9029 17.4168 17.4167V6.41667C17.4168 5.93044 17.2237 5.46412 16.8799 5.1203C16.536 4.77649 16.0697 4.58333 15.5835 4.58333H13.7502M8.25016 4.58333C8.25016 4.0971 8.44332 3.63079 8.78713 3.28697C9.13095 2.94315 9.59727 2.75 10.0835 2.75H11.9168C12.4031 2.75 12.8694 2.94315 13.2132 3.28697C13.557 3.63079 13.7502 4.0971 13.7502 4.58333M8.25016 4.58333C8.25016 5.06956 8.44332 5.53588 8.78713 5.8797C9.13095 6.22351 9.59727 6.41667 10.0835 6.41667H11.9168C12.4031 6.41667 12.8694 6.22351 13.2132 5.8797C13.557 5.53588 13.7502 5.06956 13.7502 4.58333M9.16683 12.8333H12.8335M11.0002 11V14.6667" stroke={active ? appPrimaryColor : appGreyColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}

export default HealthRecordLogo