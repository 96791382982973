import {
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { authApi } from "apis/auth.api";
import { providerApi } from "apis/provider.api";
import { pharmacyApi } from "apis/pharmacy.api";
import { patientApi } from "apis/patient.api";
import { paymentsApi } from "apis/payments.api";
import { bookAppointment } from "apis/bookAppointment.api";
import { supplementStore } from "apis/supplementStore.api";
import userSlice from "slices/user.slice";

const combinedReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  user: userSlice,
  [providerApi.reducerPath]: providerApi.reducer,
  [pharmacyApi.reducerPath]: pharmacyApi.reducer,
  [patientApi.reducerPath]: patientApi.reducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
  [bookAppointment.reducerPath]: bookAppointment.reducer,
  [supplementStore.reducerPath]: supplementStore.reducer,
});
const rootReducer = (state, action) => {
  if (action.type === 'user/resetUser') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: true,
      // immutableCheck: true
    }).concat([
      authApi.middleware,
      providerApi.middleware,
      pharmacyApi.middleware,
      patientApi.middleware,
      paymentsApi.middleware,
      bookAppointment.middleware,
      supplementStore.middleware,
    ]),
});
export default store;
