import React from 'react'
import { appGreyColor, appPrimaryColor } from 'theme/colors'

const AppointmentLogo = ({ active = false }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6665 2.75V6.41667M7.33317 2.75V6.41667M3.6665 10.0833H18.3332M6.4165 12.8333H6.42842M9.17546 12.8333H9.18004M11.9255 12.8333H11.93M14.6804 12.8333H14.685M11.9304 15.5833H11.935M6.42546 15.5833H6.43004M9.17546 15.5833H9.18004M3.6665 6.41667C3.6665 5.93044 3.85966 5.46412 4.20347 5.1203C4.54729 4.77649 5.01361 4.58333 5.49984 4.58333H16.4998C16.9861 4.58333 17.4524 4.77649 17.7962 5.1203C18.14 5.46412 18.3332 5.93044 18.3332 6.41667V17.4167C18.3332 17.9029 18.14 18.3692 17.7962 18.713C17.4524 19.0568 16.9861 19.25 16.4998 19.25H5.49984C5.01361 19.25 4.54729 19.0568 4.20347 18.713C3.85966 18.3692 3.6665 17.9029 3.6665 17.4167V6.41667Z" stroke={active ? appPrimaryColor : appGreyColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default AppointmentLogo