import { Button, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const FallbackRender = () => {

  const refreshPage = () => {
    window.location.reload();
  }

  return (
    <Stack
      direction="column"
      alignItems="start"
      justifyContent="center"
      width="100%"
      minWidth="100%"
      height="100vh"
    >
      <Stack margin="0px auto">
        <ErrorIcon sx={{ fontSize: 100, color: '#202124', margin: "0px auto" }} />
        {navigator.onLine ? <Typography color="error">Something went wrong</Typography>
          :
          <>
            <Typography fontWeight={500}>
              Try:
            </Typography>
            <List sx={{ listStyle: "disc", pl: 5, pt: 0 }}>
              <ListItem sx={{ display: "list-item", p: 0 }}>
                <ListItemText primary="Checking the network cables, modem, and router" primaryTypographyProps={{ fontWeight: 500 }} />
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0 }}>
                <ListItemText primary="Reconnecting to Wi-Fi" primaryTypographyProps={{ fontWeight: 500 }} />
              </ListItem>
            </List>
            <Typography variant='caption' fontWeight={500}>ERR_INTERNET_DISCONNECTED</Typography>
          </>
        }
        <Button sx={{ textTransform: 'none' }} onClick={refreshPage}>Refresh Page</Button>
      </Stack>
    </Stack>
  )
}

export default FallbackRender