import { useUpdateFcmTokenMutation } from 'apis/auth.api';
import { getNotificationPermission, requestForToken } from 'init-fcm';

const useFcm = () => {
  // const { id, fcm_registration_id } = useSelf();
  const [updateToken] = useUpdateFcmTokenMutation();
  const updateFcmToken = async () => {
    try {
      const permission = await getNotificationPermission();
      if (permission) {
        const token = await requestForToken();
        if (token) {
          updateToken({ fcm_registration_id: token });
        }
      }
    } catch (e) {
      console.log('Error in getting notification permission:', e);
    }
  }

  return {
    updateFcmToken,
  }

  // useEffect(() => {
  //   if (id && !fcm_registration_id) {
  //     getConfig();
  //   }
  // }, [id, fcm_registration_id]);
}

export default useFcm;