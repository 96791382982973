import React from 'react'
import { appBlackcolor, appGreyColor, appPrimaryColor } from 'theme/colors'

const SettingsLogo = ({ active = false, blackColor = false, height = "22", width = "22" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.46458 3.95725C9.85508 2.34758 12.1449 2.34758 12.5354 3.95725C12.594 4.19907 12.7089 4.42363 12.8707 4.61267C13.0324 4.8017 13.2366 4.94987 13.4664 5.0451C13.6963 5.14033 13.9454 5.17995 14.1935 5.16071C14.4415 5.14148 14.6816 5.06394 14.894 4.93442C16.3084 4.07275 17.9282 5.69158 17.0665 7.10692C16.9372 7.31927 16.8597 7.55914 16.8406 7.80704C16.8214 8.05494 16.8609 8.30387 16.9561 8.5336C17.0512 8.76332 17.1992 8.96736 17.388 9.12913C17.5768 9.2909 17.8012 9.40583 18.0428 9.46458C19.6524 9.85508 19.6524 12.1449 18.0428 12.5354C17.8009 12.594 17.5764 12.7089 17.3873 12.8707C17.1983 13.0324 17.0501 13.2366 16.9549 13.4664C16.8597 13.6963 16.8201 13.9454 16.8393 14.1935C16.8585 14.4415 16.9361 14.6816 17.0656 14.894C17.9273 16.3084 16.3084 17.9282 14.8931 17.0665C14.6807 16.9372 14.4409 16.8597 14.193 16.8406C13.9451 16.8214 13.6961 16.8609 13.4664 16.9561C13.2367 17.0512 13.0326 17.1992 12.8709 17.388C12.7091 17.5768 12.5942 17.8012 12.5354 18.0428C12.1449 19.6524 9.85508 19.6524 9.46458 18.0428C9.40599 17.8009 9.29113 17.5764 9.12935 17.3873C8.96757 17.1983 8.76344 17.0501 8.53357 16.9549C8.3037 16.8597 8.0546 16.8201 7.80653 16.8393C7.55846 16.8585 7.31844 16.9361 7.106 17.0656C5.69158 17.9273 4.07183 16.3084 4.9335 14.8931C5.06284 14.6807 5.14025 14.4409 5.15944 14.193C5.17863 13.9451 5.13906 13.6961 5.04393 13.4664C4.94881 13.2367 4.80082 13.0326 4.612 12.8709C4.42318 12.7091 4.19885 12.5942 3.95725 12.5354C2.34758 12.1449 2.34758 9.85508 3.95725 9.46458C4.19907 9.40599 4.42363 9.29113 4.61267 9.12935C4.8017 8.96757 4.94987 8.76344 5.0451 8.53357C5.14033 8.3037 5.17995 8.0546 5.16071 7.80653C5.14148 7.55846 5.06394 7.31844 4.93442 7.106C4.07275 5.69158 5.69158 4.07183 7.10692 4.9335C8.02358 5.49083 9.21158 4.99767 9.46458 3.95725Z" stroke={blackColor ? appBlackcolor : active ? appPrimaryColor : appGreyColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.25 11C8.25 11.7293 8.53973 12.4288 9.05546 12.9445C9.57118 13.4603 10.2707 13.75 11 13.75C11.7293 13.75 12.4288 13.4603 12.9445 12.9445C13.4603 12.4288 13.75 11.7293 13.75 11C13.75 10.2707 13.4603 9.57118 12.9445 9.05546C12.4288 8.53973 11.7293 8.25 11 8.25C10.2707 8.25 9.57118 8.53973 9.05546 9.05546C8.53973 9.57118 8.25 10.2707 8.25 11Z" stroke={blackColor ? appBlackcolor : active ? appPrimaryColor : appGreyColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default SettingsLogo