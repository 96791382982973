import { Stack } from '@mui/material';
import { authApi, useGetDropdownNotificationsQuery } from 'apis/auth.api';
import { QueryLoader } from 'components/Loader';
import NotificationCard from 'components/NotificationsListing/NotificationCard';
import { useEffect } from 'react';
// import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import NotificationCard from './NotificationCard';

const DropdownNotificationListing = ({ gap,
  // unreadNotifications
  unreadCount,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [next, setNext] = useState(1);
  const unreadNotifications = useSelector(state => state.user.unreadNotifications) ?? [];
  const { data, isLoading } = useGetDropdownNotificationsQuery({ pageSize: unreadNotifications?.length + unreadCount + 5 });
  const { results: notifications = [], next: nextUrl, count = 0 } = data || {};

  // const fetchMoreData = () => {
  //   setNext(nextUrl);
  // }
  const clickAction = (link) => () => {
    if (link) {
      handleClose?.()
      navigate(link);
    }
  }

  useEffect(() => {
    return () => {
      dispatch(authApi.util.invalidateTags(['DROPDOWN_NOTIFICATIONS']));
    }
  }, [dispatch])

  return (
    // <InfiniteScroll
    //   dataLength={count}
    //   next={fetchMoreData}
    //   hasMore={nextUrl !== null}
    //   loader={<>Loading ...</>}
    //   height={"340px"}
    // >
    <>
      {isLoading && <QueryLoader />}
      <Stack direction="column" gap={gap} px={1}>
        {notifications.map((item) =>
          <NotificationCard
            key={item.id}
            {...item}
            isDropdownNotification
            link={item?.payload?.click_action}
            clickAction={clickAction}
          />
        )}
      </Stack>
    </>
    // </InfiniteScroll>
  )
}

export default DropdownNotificationListing